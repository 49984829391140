<template>
  <LayoutBase class="ocean-document">
    <template
      v-if="$slots.sidebar"
      #sidebar
    >
      <slot name="sidebar" />
    </template>

    <template #default>
      <slot name="page-header" />

      <LayoutBaseContent>
        <slot name="default" />

        <template #content-sidebar>
          <slot name="document-toc" />
        </template>
      </LayoutBaseContent>
    </template>
  </LayoutBase>
</template>
